import React, { useState } from "react";

function MailchimpForm() {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await fetch(
        "https://soel.netlify.app/.netlify/functions/mailchimpApiCall",
        {
          method: "POST",
          body: JSON.stringify({
            email: email,
          }),
        }
      )
        .then((response) =>
          response.status !== 200
            ? setResponse("noe gikk galt, prøv igjen")
            : setResponse("Epost registrert")
        )
        .then((data) => console.log(data));
    } catch (error) {
      setResponse("Noe gikk galt. Prøv igjen senere.");
    }
  };

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <input
          className="newsletter-subscribe-input"
          placeholder="Din Epost her..."
          type="email"
          id="email"
          name="email"
          required
          value={email}
          onChange={handleInputChange}
        />
        <br />
        <button className="newsletter-subscribe-button" type="submit">
          Meld deg på ⟶
        </button>
      </form>
      <p style={{ marginLeft: "40px" }}>{response}</p>
      <br />
    </>
  );
}

export default MailchimpForm;
