import React from "react";
import "../styles/FrontPageImages.css";

import frontPageImage from "../assets/images/FrontpageImages/1.jpeg";

const FrontPageImages = () => {
  return (
    <div className="frontpage-images">
      <div className="image-container">
        <img className="frontpage-image" src={frontPageImage} alt="siteImage" />
      </div>
      <div className="frontpage-image-text">
        <div className="image-text">Distributør av hud- og hårpleie</div>
      </div>
    </div>
  );
};
export default FrontPageImages;
