import Assets from "../assets/assets";

const SocialBar = () => {
  return (
    <div className="socialbar-container">
      <div className="socialbar-wrapper">
        <div className="socialbar-icon-circle">
          <a
            target="_blank"
            href="https://www.facebook.com/soel.no"
            rel="noreferrer"
          >
            <img style={{ width: "30px" }} src={Assets.icons.facebook} />
          </a>
        </div>
        <div className="socialbar-icon-circle">
          <a
            target="_blank"
            href="https://www.instagram.com/soel.no/"
            rel="noreferrer"
          >
            <img style={{ width: "30px" }} src={Assets.icons.instagram} />
          </a>
        </div>
        <div className="socialbar-icon-circle">
          <a
            target="_blank"
            href="https://www.linkedin.com/company/so%C3%A9l-as/"
            rel="noreferrer"
          >
            <img style={{ width: "30px" }} src={Assets.icons.linkedin} />
          </a>
        </div>
        <div className="socialbar-icon-circle">
          <a
            target="_blank"
            href="https://www.youtube.com/@soelas5140"
            rel="noreferrer"
          >
            <img style={{ width: "30px" }} src={Assets.icons.youtube} />
          </a>
        </div>
        <div className="socialbar-icon-circle">
          <a
            target="_blank"
            href="https://www.tiktok.com/@soel.no"
            rel="noreferrer"
          >
            <img style={{ width: "30px" }} src={Assets.icons.tiktok} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialBar;
