import "../styles/Contact.css";

import promotionPicture from "../assets/images/Carousel/2.jpg";
import Agnes from "../assets/images/Agnes.jpeg";
import Sussane from "../assets/images/Sussane.jpeg";
import AgnesAndSussane from "../assets/images/Agnes_and_Sussane.jpg";
import Irmina from "../assets/images/IrminaPortrait.jpg";

const Contact = () => {
  return (
    <>
      <div className="section-one-container">
        <div className="contact-text-container">
          <div className="sub-heading">KONTAKT OSS</div>
          <div className="mobile-image">
            <img
              src={AgnesAndSussane}
              style={{ width: "75%", marginBottom: "25px" }}
            />
          </div>
          For å bli forhandler/leverandør, kontakt oss på:
          <br />
          <a
            style={{ paddingBottom: "10px", textDecoration: "none" }}
            href="mailto: post@soel.no"
          >
            post@soel.no
          </a>
          <div>
            Finner du ikke det du lurer på her på nettsiden eller har du
            spørsmål knyttet til ett produkt/merke, ta kontakt med oss på:
            <br />
            <a
              style={{ textDecoration: "none" }}
              href="mailto: kundeservice@soel.no"
            >
              kundeservice@soel.no
            </a>
          </div>
          <article>
            <br />
            <h3>Kontakt oss</h3>
            <ul className="footer-list">
              <li>Lahelleveien 8,</li>
              <li>3140 Nøtterøy </li>
              <li>Org.nr 928 676 331</li>
            </ul>
          </article>
        </div>
        <div className="contact-image-container">
          <img
            style={{ width: "500px", height: "500px", objectFit: "cover" }}
            alt="promotionPicture"
            src={promotionPicture}
          />
        </div>
      </div>
      <div className="contactcard-container">
        <div className="contactcard">
          <img alt="portrait" style={{ height: "150px" }} src={Sussane} />
          <ul className="contactcard-text">
            <li>
              <b>Susanne Eliza Enger Holm</b>
            </li>
            <li>markeds- og salgssjef</li>
            <li>E-post: susanne@soel.no</li>
            <li>tlf. +47 953 62 116</li>
          </ul>
        </div>
        <div className="contactcard">
          <img alt="portrait" style={{ height: "150px" }} src={Agnes} />
          <ul className="contactcard-text">
            <li>
              <b>Agnes Moe</b>
            </li>
            <li>daglig leder</li>
            <li>E-post: agnes@soel.no</li>
            <li>tlf. +47 450 39 832</li>
          </ul>
        </div>
        <div className="contactcard">
          <img alt="portrait" style={{ height: "150px" }} src={Irmina} />
          <ul className="contactcard-text">
            <li>
              <b>Irmina Stepsyte</b>
            </li>
            <li>Kundekonsulent</li>
            <li>E-post: irmina@soel.no</li>
            <li>tlf. +47 484 06 973</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Contact;
