import React, { useState } from "react";
import ProductMenu from "./ProductMenu";
import ProductInfo from "./ProductInfo";
import { productMap } from "../../assets/Database";
import { useLocation } from "react-router-dom";

import "./ProductMenu.css";

function ProductPage() {
  const location = useLocation();
  const from = location.state?.from;

  const startPage = productMap.find((product) => product.name === "Klairs");
  const fromLogoClick = productMap.find((product) => product.name === from);

  const [openPage, setOpenPage] = useState(fromLogoClick || startPage);

  const setPage = (product) => {
    setOpenPage(product);
  };

  return (
    <div className="productContainer">
      <ProductMenu productMap={productMap} setPage={setPage} />
      <ProductInfo product={openPage} />
    </div>
  );
}

export default ProductPage;
