import "./App.css";
import Header from "./components/Header/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer";

import { HashRouter as BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./pages/Contact";
import AboutUs from "./pages/AboutUs";
import ProductPage from "./pages/productPage/ProductPage";
import Outlets from "./pages/Outlets";

function App() {
  return (
    <BrowserRouter>
      <div className="mainPage">
        <Header />
        <div className="page-container">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Home" element={<Home />} />
            <Route exact path="/Contact" element={<Contact />} />
            <Route exact path="/AboutUs" element={<AboutUs />} />
            <Route exact path="/ProductPage" element={<ProductPage />} />
            <Route exact path="/Outlets" element={<Outlets />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
