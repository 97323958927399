import React, { useState, useEffect } from "react";

import MobileOutlets from "../components/Outlets/MobileOutlets";
import Outlets from "../components/Outlets/DesktopOutlets";

import "../styles/Outlets.css";
const OutletsPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 870);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 870);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <div>{isMobile ? <MobileOutlets /> : <Outlets />}</div>;
};

export default OutletsPage;
