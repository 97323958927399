import React, { useState, useEffect } from "react";
import "../styles/Footer.css";

import Assets from "../assets/assets";
import SocialBar from "./SocialBar";

import { Link, useLocation } from "react-router-dom";
import MailchimpForm from "./MailchimpForm";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 1050);
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isOutletsPage = location.pathname === "/Outlets";
  const footerClassNames = `footer ${
    isOutletsPage && isMobile ? "footer-fixed" : ""
  }`;

  const renderDesktopFooter = () => (
    <div className="footer_container">
      <img
        className="footer-logo"
        style={{ width: "200px", height: "60px", marginRight: "40px" }}
        src={Assets.images.logo}
        alt="Logo"
      />
      {/* <div className="newsletter-container">
        <h3>Nyhetsbrev</h3>
        <MailchimpForm />
      </div> */}
    </div>
  );

  return (
    <footer className={footerClassNames}>
      {!isMobile && renderDesktopFooter()}
      <SocialBar />
    </footer>
  );
};

export default Footer;
