import React from "react";
import { Link } from "react-router-dom";

import Assets from "../../assets/assets";

import "../../styles/NavBar.css";

const NavBar = () => {
  return (
    <>
      <ul className="header_links">
        <li>
          <Link to="/Home">Hjem</Link>
        </li>
        <li>
          <Link to="/ProductPage">Merker</Link>
        </li>
        <li>
          <Link to="/AboutUs">Om Oss</Link>
        </li>
        <Link to="/Home">
          <img
            alt="logo"
            className="logo"
            src={Assets.images.logo}
            style={{ width: "160px", height: "100%" }}
          />
        </Link>
        <li>
          <Link to="/Contact">Kontakt</Link>
        </li>
        <li>
          <Link to="/Outlets">Utsalgsteder</Link>
        </li>
        {/* <li>
          <a href="https://soelnorge.wordpress.com/">Blogg</a>
        </li> */}
      </ul>
    </>
  );
};
export default NavBar;
