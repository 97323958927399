import React, { useState } from "react";
import "../../styles/HamburgerMenu.css";
import Assets from "../../assets/assets";
import { Link } from "react-router-dom";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className="hamburger-menu">
      <button
        className={`hamburger-menu__button ${isOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div className="hamburger-menu__bar" />
        <div className="hamburger-menu__bar" />
        <div className="hamburger-menu__bar" />
      </button>
      <nav className={`hamburger-menu__nav ${isOpen ? "open" : ""}`}>
        <ul>
          <li>
            <Link to="/Home" onClick={closeMenu}>
              Hjem
            </Link>
          </li>
          <li>
            <Link to="/ProductPage" onClick={closeMenu}>
              Merker
            </Link>
          </li>
          <li>
            <Link to="/AboutUs" onClick={closeMenu}>
              Om Oss
            </Link>
          </li>
          <li>
            <Link to="/Contact" onClick={closeMenu}>
              Kontakt
            </Link>
          </li>
          <li>
            <Link to="/Outlets" onClick={closeMenu}>
              Utsalgsteder
            </Link>
          </li>
          {/* <li>
            <a href="https://soelnorge.wordpress.com/" onClick={closeMenu}>
              Blogg
            </a>
          </li> */}
        </ul>
      </nav>
      <Link to="/Home">
        <img
          alt="logo"
          className="logo"
          src={Assets.images.logo}
          style={{ width: "160px", height: "100%", margin: "20px" }}
        />
      </Link>
    </div>
  );
};

export default HamburgerMenu;
