import "../styles/AboutUs.css";

import imageOne from "../assets/images/productImages/ByWishtrend/3.jpg";
import imageTwo from "../assets/images/productImages/RatedGreen/2.jpg";
import AgnesAndSussane from "../assets/images/Agnes_and_Sussane.jpg";
import Irmina from "../assets/images/Irmina.jpg";

import gaselleAward from "../assets/images/Awards/gaselle.png";
import costumeAward from "../assets/images/Awards/costume.png";

import Assets from "../assets/assets";
const AboutUs = () => {
  return (
    <div>
      <div className="top-section-container">
        <div className="top-section-image" />
        <div className="textbox">
          <div className="sub-heading">OM OSS</div>
          Soél AS er importør av og har salgsrett i Norge og Norden for alle
          våre merker. Vårt produktutvalg kan distribueres i utsalgsteder og
          nettbutikker som ønsker å tilby førsteklasses produkter til sine
          kunder. <br />
          <br />
          Følg oss gjerne på sosiale medier for å holde deg oppdatert om siste
          nytt.
          <div className="aboutUs-social-media-icons">
            <a
              target="_blank"
              href="https://www.instagram.com/soel.no/"
              rel="noreferrer"
            >
              <img
                style={{ width: "40px" }}
                src={Assets.icons.instagramWhite}
              />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/soel.no"
              rel="noreferrer"
            >
              <img style={{ width: "40px" }} src={Assets.icons.facebookWhite} />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/so%C3%A9l-as/"
              rel="noreferrer"
            >
              <img style={{ width: "40px" }} src={Assets.icons.linkedinWhite} />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/@soelas5140"
              rel="noreferrer"
            >
              <img style={{ width: "40px" }} src={Assets.icons.youtubeWhite} />
            </a>
            <a
              target="_blank"
              href="https://www.tiktok.com/@soel.no"
              rel="noreferrer"
            >
              <img style={{ width: "40px" }} src={Assets.icons.tiktokWhite} />
            </a>
          </div>
        </div>
      </div>
      <div className="section-text">
        Vi selger kun produkter vi ville anbefalt vår beste venn
      </div>
      <div className="section-container">
        <div className="section-wrapper">
          <div className="section-text-wrapper">
            <div className="sub-heading">HVORFOR SAMARBEIDE MED SOÉL</div>
            <div>
              <ul>
                <li>
                  Soél har siden oppstarten i 2015 hatt en god vekst og har
                  etablert seg som en solid og pålitelig aktør i Norden.
                </li>
                <li>
                  Vi jobber aktivt sammen med våre samarbeidspartnere for å
                  sikre høy synlighet av produktene og vant i 2020 Norway
                  Costume Beauty Awards for «Årets Beste hudpleiemerke» for
                  Klairs og har blitt nominert til en rekke priser for flere av
                  våre merker.
                </li>
                <li>
                  Vi er opptatt av at produktene vi selger er av svært høy
                  kvalitet og er godkjent for det nordiske markedet.
                </li>
                <li>
                  Vi tar alle våre samarbeidspartnere på alvor og er opptatt av
                  åpenhet og god dialog.
                </li>
                <li>
                  Vi gjør vår ytterste for å sikre kvalitet og god service i
                  alle ledd.
                </li>
                <li>Vi har stort lager og leverer raskt.</li>
              </ul>
            </div>
            <div className="awards-container">
              <img
                src={gaselleAward}
                style={{ height: "150px", marginRight: "30px" }}
              />
              <img
                src={costumeAward}
                style={{ height: "150px", marginRight: "30px" }}
              />
            </div>
          </div>
          <div className="section-image-container">
            <img
              className="section-image"
              src={imageOne}
              style={{ height: "600px" }}
            />
          </div>
        </div>
      </div>
      <div className="section-text">
        <div className="sub-heading-text">
          Ønsker du å bli forhandler av våre merker eller ønsker du å presentere
          ett nytt merke for oss, ta kontakt på post@soel.no. Vi gleder oss til
          å høre fra deg!
        </div>
      </div>
      <div className="section-container">
        <div className="section-wrapper-reverse">
          <div className="section-text-wrapper">
            <div className="sub-heading">HISTORIEN BAK SOÉL</div>
            <div className="sub-heading-text">
              Soél AS ble opprettet i 2015 av søstrene Susanne Eliza Enger Holm
              og Helene Emilie Enger. Susanne hadde lenge vært på utkikk etter
              gode hudpleieprodukter som var pleiende og effektive for hennes
              sensitive hud. Da hun kom over Koreansk hudpleie og Klairs, innså
              hun raskt at dette måtte deles med flere. Soél fikk muligheten til
              å distribuere hudpleieserien Klairs i Norge i 2015 og var en av de
              første til å introdusere koreansk hudpleie på det norske markedet
              via egen nettbutikk. <br />
              Etter hvert har Soél utvidet sortimentet med flere merker og i
              takt med at selskapet vokste, ble nettbutikken lagt ned i 2019 for
              å kunne fokusere fullt på import og distribusjon.
              <br />
              <br />I dag har Soél i tillegg til Klairs, hudpleiemerkene By
              Wishtrend (2018), Haruharu Wonder (2022), hårpleiemerket Rated
              Green (2021) og solpleiemerket Suntique (2022).
            </div>
          </div>
          <div className="section-image-reverse-container">
            <img className="section-image" src={imageTwo} />
          </div>
        </div>
      </div>
      <div className="section-text">
        <div className="sub-heading">OMORGANISERING 2022</div>
        <div className="sub-heading-text">
          Soél gjennomførte i 2022 en konsernfusjon for å omstrukturere
          eierforholdene i Soél AS på en mer hensiktsmessig måte. Navn, øvrige
          rettigheter og forpliktelser ble i sin helhet fra 1.juni 2022
          overdratt til nytt organisasjonsnummer og «gamle» Soél ble følgelig
          slettet iht reglene om fusjon i aksjeloven.
        </div>
      </div>
      <div className="section-container">
        <div className="section-wrapper-aboutUs">
          <div style={{ width: "100%" }} className="section-text-wrapper">
            <div className="sub-heading">SOÉL I DAG</div>
            <div className="sub-heading-text">
              Soél består i dag av Susanne Enger Holm, Agnes Moe og Irmina
              Stepsyte.
              <br />
              <br />
              Susanne er grunnlegger og eier av Soél, og jobber som salgs- og
              markedssjef. Hun er utdannet barnevernspedagog, og har siden
              oppstarten i 2015, opparbeidet seg ett bredt nettverk innenfor
              hår- og hudpleie. Hennes dype interesse, engasjement,
              samarbeidsevne og kunnskap for å finne gode produkter, har vært
              helt avgjørende for Soéls suksesshistorie.
              <br />
              <br />
              Agnes ble ansatt som daglig leder i 2021 og er også eier av Soél.
              Hun er utdannet jurist og har bred og lang erfaring innenfor flere
              fagfelt. I kombinasjon med sin bakgrunn, strategiske evner og
              brennende engasjement for gode hår- og hudpleieprodukter, har hun
              bidratt til å styrke Soél fremover mot nye mål.
              <br />
              <br />
              Irmina startet i Soél i 2023 og er kundekonsulent med ansvar for
              opplæring og oppfølging av Soéls kunder, i tillegg til å være
              ansvarlig for Sosiale Mediaer. Hun er utdannet apotektekniker, har
              en bred erfaring fra apotekbransjen og har et stort engasjement
              for å veilede Soéls kunder og sluttforbruker.
              <br />
              <br />
              Sammen er de opptatt av høy kvalitet i alle ledd og at Soéls
              samarbeidspartnere skal føle seg ivaretatt til enhver tid.
            </div>
          </div>
          <div className="soel-crew-images">
            <img
              style={{ height: "340px" }}
              className="section-image"
              src={AgnesAndSussane}
            />
            <img
              style={{ height: "340px" }}
              className="section-image"
              src={Irmina}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
