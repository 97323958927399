import { useState } from "react";
import { outletData } from "../../assets/Database";
import "../../styles/Outlets.css";

const MobileOutlets = () => {
  const [selectedBrand, setSelectedBrand] = useState("");

  function getHostName(url) {
    const matches = url.match(/^https?:\/\/(?:www\.)?([\w-]+)\.\w{2,}$/i);
    return matches ? matches[1] : null;
  }

  function handleBrandSelect(e) {
    setSelectedBrand(e.target.value);
  }

  return (
    <div className="mobile-outlet-container">
      <select
        className="mobile-outlet-dropdown"
        onChange={handleBrandSelect}
        value={selectedBrand}
      >
        <option value="">Velg et merke</option>
        {outletData.brands.map((brand) => (
          <option key={brand.name} value={brand.name}>
            {brand.name}
          </option>
        ))}
      </select>
      {!selectedBrand && (
        <div style={{ margin: "25px" }}>
          Vi distribuerer våre merker til en rekke butikker og nettbutikker. For
          å se hvilke utsalgssteder som fører våre merker, Trykk på «Velg ett
          merke» øverst på denne siden og klikk på ett av merkene så vil du få
          en liste over alle våre utsalgssteder.
        </div>
      )}
      {selectedBrand &&
        outletData.brands.map(
          (brand) =>
            brand.name === selectedBrand && (
              <div
                key={brand.name}
                style={{ marginBottom: "100px", paddingBottom: "100px" }}
              >
                <img
                  alt="brandImage"
                  style={{ width: "100px", marginBottom: "10px" }}
                  src={brand.logo}
                />
                {Object.entries(brand.countries).map(([country, info]) => (
                  <div key={country}>
                    <h3 style={{ fontSize: "12px", marginTop: "5px" }}>
                      {country.toUpperCase()}
                    </h3>
                    <ul style={{ margin: "5px" }}>
                      {info.urls.map((url) => (
                        <li key={url}>
                          <a
                            className="outletName"
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {getHostName(url)}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )
        )}
    </div>
  );
};

export default MobileOutlets;
