import React, { useState, useEffect } from "react";
import "../styles/ImageTransitionGallery.css";

const ImageTransitionGallery = ({ images, time = 4000 }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, time);

    return () => clearInterval(interval);
  }, [images.length, time]);

  return (
    <div className="image-gallery">
      {images.map((image, i) => (
        <img
          key={i}
          src={image}
          alt={`Image ${i}`}
          className={`image-gallery-image ${i === index ? "active" : ""}`}
        />
      ))}
    </div>
  );
};

export default ImageTransitionGallery;
