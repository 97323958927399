import Assets from "../../assets/assets";

import ImageTransitionGallery from "../../components/ImageGallery";

const ProductInfo = ({ product }) => {
  let productImage = product.pictures.forEach((productImage) => {
    return productImage;
  });
  const productImages = [{ original: productImage, banner: product.banner }];

  return (
    <div className="product-page-setup">
      <div className="product-page-title-container">
        <img src={productImages[0].banner} className="product-title-image" />
        <div className="product-header">
          <div className="product-title">{product.name}</div>
          <div className="product-social-media-icons">
            <a href={product.instagram}>
              <img
                style={{ width: "40px" }}
                src={Assets.icons.instagramWhite}
              />
            </a>
            <a href={product.facebook}>
              <img style={{ width: "40px" }} src={Assets.icons.facebookWhite} />
            </a>
          </div>
        </div>
      </div>
      <div className="product-section-container">
        <div className="product-info-container">
          <div className="product-info-carousel-wrapper">
            <div className="product-info-carousel-container">
              <ImageTransitionGallery images={product.pictures} />
            </div>
          </div>
          <div
            className="product-info-text"
            dangerouslySetInnerHTML={{ __html: product.productText }}
          />
        </div>
        <div>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={product.productPictures}
          >
            Productbilder
          </a>
          <br />
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={product.miljoPictures}
          >
            Miljøbilder
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
