import React, { useEffect, useState } from "react";

import HamburgerMenu from "../Header/HamburgerMenu";
import NavBar from "../Header/Navbar";

const Header = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 870);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 870);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="navbar-container">
      {isMobile ? <HamburgerMenu /> : <NavBar />}
    </div>
  );
};

export default Header;
