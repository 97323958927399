import React, { useState, useEffect } from "react";
import "./ProductMenu.css";

const ProductMenu = ({ productMap, setPage }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 870) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="productmenu-container">
      {isMobile ? (
        <select
          className="product-dropdown"
          onChange={(event) => {
            const productName = event.target.value;
            const product = productMap.find(
              (product) => product.name === productName
            );
            setPage(product);
          }}
        >
          {productMap.map((product) => (
            <option key={product.name} value={product.name}>
              {product.name}
            </option>
          ))}
        </select>
      ) : (
        <ul>
          {productMap.map((product) => (
            <li key={product.name}>
              <button
                onClick={() => {
                  setPage(product);
                }}
                className="product-button"
              >
                {product.name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProductMenu;
