import React from "react";
import { outletData } from "../assets/Database";

import "../styles/LogoCarousel.css";

import { Link } from "react-router-dom";

const LogoCarousel = () => {
  let brands = outletData.brands;

  return (
    <>
      <div className="carousel-wrapper">
        {brands.map((brand) => (
          <div className="logo-container" key={brand.name}>
            <Link state={{ from: brand.name }} to="/ProductPage">
              <img src={brand.logo} alt={brand.name} className="logo" />
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default LogoCarousel;
