import { outletData } from "../../assets/Database";

import "../../styles/Outlets.css";
const Outlets = () => {
  function getHostName(url) {
    const matches = url.match(/^https?:\/\/(?:www\.)?([\w-]+)\.\w{2,}$/i);
    return matches ? matches[1] : null;
  }

  return (
    <div className="brand-outlet-container">
      {outletData.brands.map((brand) => (
        <div key={brand.name} className="brand-list">
          {/* <div className="logo">
            <img
              alt="brandImage"
              style={{ width: "100px", marginBottom: "10px" }}
              src={brand.logo}
            />
          </div> */}
          <div className="brandName" key={brand.name}>
            <h2 style={{ marginBottom: "10px" }}>{brand.name}</h2>
            {Object.entries(brand.countries).map(([country, info]) => (
              <div key={country}>
                <h3
                  className="country"
                  style={{ fontSize: "12px", margin: "5px 0px 10px 0px" }}
                >
                  {country.toUpperCase()}
                </h3>
                <ul style={{ margin: "5px" }}>
                  {info.urls.map((url) => (
                    <li key={url}>
                      <a
                        className="outletName"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getHostName(url)}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Outlets;
