import klairOne from "./images/productImages/Klairs/1.jpg";
import klairTwo from "./images/productImages/Klairs/2.jpg";
import klairThree from "./images/productImages/Klairs/3.jpg";
import klairFour from "./images/productImages/Klairs/4.jpg";

import imfromOne from "./images/productImages/imFrom/1.jpg";
import imfromTwo from "./images/productImages/imFrom/2.jpg";
import imfromThree from "./images/productImages/imFrom/3.jpg";
import imfromFour from "./images/productImages/imFrom/4.JPG";
import imfromFive from "./images/productImages/imFrom/5.jpg";
import imfromSix from "./images/productImages/imFrom/6.jpg";
import imfromSeven from "./images/productImages/imFrom/7.jpg";

import houseOfDowhaOne from "./images/productImages/HouseOfDohwa/1.jpg";
import houseOfDowhaTwo from "./images/productImages/HouseOfDohwa/2.jpg";
import houseOfDowhaThree from "./images/productImages/HouseOfDohwa/3.jpg";
import houseOfDowhaFour from "./images/productImages/HouseOfDohwa/4.jpg";

import steambaseOne from "./images/productImages/Steambase/1.jpg";
import steambaseTwo from "./images/productImages/Steambase/2.jpg";
import steambaseThree from "./images/productImages/Steambase/3.jpg";
import steambaseFour from "./images/productImages/Steambase/4.jpg";

import meisaniOne from "./images/productImages/Meisani/1.jpeg";
import meisaniTwo from "./images/productImages/Meisani/2.jpeg";
import meisaniThree from "./images/productImages/Meisani/3.jpg";
import meisaniFour from "./images/productImages/Meisani/4.jpeg";

import ratedGreenOne from "./images/productImages/RatedGreen/1.jpg";
import ratedGreenTwo from "./images/productImages/RatedGreen/2.jpg";

import byWishtrendOne from "./images/productImages/ByWishtrend/1.jpg";
import byWishtrendTwo from "./images/productImages/ByWishtrend/2.jpg";
import byWishtrendThree from "./images/productImages/ByWishtrend/3.jpg";

import suntiqueOne from "./images/productImages/Suntique/1.jpg";
import suntiqueTwo from "./images/productImages/Suntique/2.jpg";
import suntiqueThree from "./images/productImages/Suntique/3.jpg";

import haruharuwonderOne from "./images/productImages/haruharuwonder/1.jpg";
import haruharuwonderTwo from "./images/productImages/haruharuwonder/2.jpg";
import haruharuwonderThree from "./images/productImages/haruharuwonder/3.jpeg";
import haruharuwonderFour from "./images/productImages/haruharuwonder/4.jpg";

import klairsLogo from "./images/productLogos/Klairs.png";
import imFromLogo from "./images/productLogos/imFrom.png";
import haruharuLogo from "./images/productLogos/haruharuWonder.png";
import ratedGreenLogo from "./images/productLogos/RatedGreen.png";
import wishtrendLogo from "./images/productLogos/Wishtrend.png";
import suntiqueLogo from "./images/productLogos/Suntique.png";
import houseOfDowhaLogo from "./images/productLogos/HouseOfDowha.png";
import meisaniLogo from "./images/productLogos/Meisani.png";
import steamBaseLogo from "./images/productLogos/Steambase.png";

import klairsBanner from "./images/productImages/Klairs/1.jpg";
import imFromBanner from "./images/productImages/imFrom/banner.jpg";
import byWishtrendBanner from "./images/productImages/ByWishtrend/bannerWishtrend.png";
import haruBanner from "./images/productImages/haruharuwonder/haruBanner.jpeg";
import RatedgreenBanner from "./images/productImages/RatedGreen/ratedGreenBanner.jpeg";
import suntiqueBanner from "./images/productImages/Suntique/suntiqueBanner.jpeg";
import houseOfDowhaBanner from "./images/productImages/HouseOfDohwa/HouseOfDowhaBanner.jpg";
import meisaniBanner from "./images/productImages/Meisani/MeisaniBanner.jpg";
import steambaseBanner from "./images/productImages/Steambase/banner.png";

export const productMap = [
  {
    name: "Klairs",
    pictures: [klairOne, klairTwo, klairThree, klairFour],
    banner: klairsBanner,
    instagram: "https://www.instagram.com/klairs.norge",
    facebook: "https://www.facebook.com/klairs.no/",
    productPictures:
      "https://www.dropbox.com/sh/uax1enslu4jmqs4/AACNcJaGnr7y9VCU5mf4dNRja?dl=0",
    miljoPictures:
      "https://www.dropbox.com/sh/okm5pbnrgj43csh/AADIMLe0iBgkICC7583aT5Ava?dl=0",
    productText: `
        Klairs er en koreansk hudpleieserie og produktene inneholder de viktigste
        ingrediensene fra rene råvarer, dvs. ingen farger, alkoholer, parabener
        eller parfymer. Alt for å gi en sunnere og sterkere hud, naturlig og
        effektivt. Produktene kommer i økologisk emballasje og ingen produkter
        er testet på dyr. Klairs er et vegansk og prisbelønnet merke, som ble
        grunnlagt i 2010, og har en sunn og ukomplisert tilnærming til hudpleie.
        <br />
        <br />
        Siden starten har Klairs spesialisert seg og fokusert sin forskning på
        ingredienser og råvarer for sensitiv hud. Produktene forbedrer ikke bare
        hudens elastisitet, men lindrer også irritert hud for å gjøre den
        naturlig sterk, frisk og elastisk.
        <br />
        <br />
        <b>“Simple but enough”</b>
        <br />
        <br />
        Klairs tilbyr alt fra rens og fuktgivning til beroligende og pleiende
        produkter. “Simple but enough” for huden din som påvirkes av
        værforandringer og livsstil.
        <br />
        <br />
        <b>“Basic but ultimate”</b>
        <br />
        <br />
        Klairs jobber hardt for å gi deg det beste innen hudpleie, slik at det
        skal være enkelt for deg å få en frisk og sunn hud.
        <br />
        <br />
        <b>“Mild but powerful”</b>
        <br />
        <br />
        Bak Klairs produkter ligger det mange år med forskning. Resultatet er
        en produktserie som gjenoppbygger huden, gir forbedret elastitet og roer
        ned irritert hud.
`,
  },
  {
    name: "I'm From",
    pictures: [
      imfromOne,
      imfromTwo,
      imfromThree,
      imfromFour,
      imfromFive,
      imfromSix,
      imfromSeven,
    ],
    banner: imFromBanner,
    instagram: "https://www.instagram.com/imfrom_global/",
    facebook: "https://www.facebook.com/imfromcosmetic/",
    productPictures:
      "https://www.dropbox.com/scl/fo/rgxzxv60ncdgw95fjgmav/h?rlkey=z6xcpyvb4byuxik62qjr54eq4&dl=0",
    miljoPictures:
      "https://www.dropbox.com/scl/fo/k3tm27s9r0w1d186h74za/h?rlkey=5lbroi9t7acdl68u0fxrr7l24&dl=0",
    productText: `
          I'm from er et hudpleiemerke som er inspirert av Koreas tradisjonelle behandlingsmetoder. 
          I'm from har rene ingredienser av høy kvalitet fra den koreansk naturen, 
          som ikke skader miljøet.
          <br />
          <br />
          I’m from er kjent for sine naturlige, trygge og effektive produkter som bidrar til å til en sunnere, 
          mer ungdommelig og strålende hud. 
          <br />
          <br />
          I’m from tilbyr en helhetlig hudpleie som pleier både huden og sjelen. 
          De har benyttet seg av naturmedisin og kombinert det med innovative formuleringer 
          for å skape et sortiment som harmonerer med hudens behov.
   `,
  },
  {
    name: "House of Dowha",
    pictures: [
      houseOfDowhaOne,
      houseOfDowhaTwo,
      houseOfDowhaThree,
      houseOfDowhaFour,
    ],
    banner: houseOfDowhaBanner,
    instagram: "https://www.instagram.com/houseofdohwa/",
    productPictures:
      "https://www.dropbox.com/scl/fo/c0ldt5mhmyfpqmqwgkllb/h?rlkey=82xwfaco4l15howerb63pt2iw&dl=0",
    miljoPictures:
      "https://www.dropbox.com/scl/fo/mllmkxsmw8joephvcwzin/h?rlkey=qe1mnfu5oxx86pxyquns5okfr&dl=0",
    productText: `
      House of Dohwa er et eksklusivt merke som tilbyr tradisjonell 
      koreansk hudpleie med en moderne vri. De har tatt i bruk eldgamle 
      tradisjoner og gjenskapt ingredienser for å kunne tilby deg den 
      ettertraktede koreanske skjønnhetslivsstilen.
      <br />
      <br />
      Den tradisjonelle hemmeligheten bak koreansk hudpleie har alltid vært å etterstrebe en 
      naturlig balanse i huden. Dette oppnås ved å bruke naturlige produkter 
      og la huden forynge seg selv fra innsiden. House of Dohwa har implementert 
      denne filosofien i sine produkter, for å gi deg en hudpleierutine som virkelig 
      forsøker å forbedre huden din.
      <br />
      <br />
      En av nøkkelingrediensene i produktene fra House of Dohwa er ekstrakter fra 
      tradisjonelt dyrket koreansk ris. Risen er dyrket, sanket og høstet på tradisjonelt vis,
      man unngår bruk av pepsider, kjemikalier og plast. Basen i alle produktene er 
      satt sammen av ekstrakter fra hele 9 ulike typer ris, for å sikre at huden din blir myk, 
      fuktig og glødende.
      <br />
      <br />
      House of Dohwa skiller seg også ut ved å være et vegansk merke. 
      De har tatt et standpunkt for å sikre at ingen dyr lider for å lage deres hudpleieprodukter. 
      Dette gjør det til et merke forbeholdt deg som ønsker å bruke dyrevennlige og miljøvennlige 
      produkter.
      <br />
      <br />
      Med House of Dohwa kan du få en autentisk opplevelse av koreansk hudpleie i ditt eget hjem. 
      Du kan oppnå en frisk og strålende hud ved å bruke deres produkter, som er utviklet med en 
      moderne touch uten å miste de tradisjonelle og effektive egenskapene fra koreansk hudpleie.
   `,
  },
  {
    name: "Rated Green",
    pictures: [ratedGreenOne, ratedGreenTwo],
    banner: RatedgreenBanner,
    instagram: "https://www.instagram.com/rated_green/",
    facebook: "https://www.facebook.com/profile.php?id=100057832942236",
    productPictures:
      "https://www.dropbox.com/sh/kgh3k14soat1mnv/AAC_tThe53fAsgM_h0FmJRF-a?dl=0",
    miljoPictures:
      "https://www.dropbox.com/sh/kgh3k14soat1mnv/AAC_tThe53fAsgM_h0FmJRF-a?dl=0",
    productText: `
        Rated Green er et koreansk hårpleiemerke, utviklet for å
        skjemme bort hår og hodebunn. Produktene er veganske, økologiske og fri
        for SLS og SLES.
        <br />
        <br />
        Rated Green har med sine produkter ønsket å redefinere naturlig
        hårpleie. Alle produktene inneholder organiske ingredienser og er
        Ecocert sertifisert. Flere av produktene er i tillegg Eve Vegan
        sertifisert som garanterer at produktene er veganske. For å hindre at
        viktige næringsstoffer forsvinner i utvinningsprosessen, lages Rated
        Green ved hjelp av kaldpressing og kaldbrygging. Produktene inneholder
        ingen skadelige ingredienser og er dermatologisk testet.
        <br />
        <br />
        Produktserien inneholder produkter for enhver hår- og hodebunnstype.
        Rated Green har fokus på at en sunn og god hodebunn gir et sunt og
        vakkert hår. De har derfor utviklet en rekke Scalp Paks som er en hår-
        og hodebunnskur som også gir pleie for håret, på lik linje med en
        balsam/hårkur. Rated Green har også utviklet hodebunnssprayer med
        kaldpresset rosmarin for å berolige tørr og irritert hodebunn, samt
        stimulere til økt hårvekst.
   `,
  },
  {
    name: "By Wishtrend",
    pictures: [byWishtrendOne, byWishtrendTwo, byWishtrendThree],
    banner: byWishtrendBanner,
    instagram: "https://www.instagram.com/bywishtrend.norge/",
    facebook: "https://www.facebook.com/profile.php?id=100063516938809",
    productPictures:
      "https://www.dropbox.com/sh/075fru2ws7lnshk/AACduVpm73wmpgbIKCux6tSCa?dl=0",
    miljoPictures:
      "https://www.dropbox.com/sh/2fhonnjaohbhxdb/AABHdR4Ln4_kdcJ7eC6iDlpva?dl=0",
    productText: `
        By Wishtrend er et koreansk hudpleiemerke hvor produktene inneholder
        enkle og aktive ingredienser som jevner ut hudtonen, øker fastheten og
        gir glød ved hjelp av høykonsentrert C- og E-vitamin og milde AHA-syrer.
        <br />
        <br />
        By Wishtrend er en serie som passer alle hudtyper, også sensitiv hud og
        problemhud. Produktene er enkle å bruke og de virkningsfulle
        ingrediensene gir maksimal effekt og en tydelig forbedret hud. Serien
        passer spesielt godt til deg som har problemer med akne, pigmentflekker,
        linjer, arr, rødhet og fet hud.
        <br />
        <br />
        Pure Vitamin C21,5 Advanced Serum var det første produktet By Wishtrend
        lanserte og siden den gang har By Wishtrend lansert flere innovative
        produkter. Hele serien er uten parabener, fenoxietanol, fargestoffer,
        syntetisk duft eller mineraloljer.
      `,
  },
  {
    name: "Meisani",
    pictures: [meisaniOne, meisaniTwo, meisaniThree, meisaniFour],
    banner: meisaniBanner,
    instagram: "https://www.instagram.com/meisaniofficial/",
    facebook: "https://m.facebook.com/p/Meisani-100083157319270/",
    productPictures:
      "https://www.dropbox.com/scl/fo/g7iy1euf0xlvdrcqugg56/h?rlkey=94sjslbccd6icwq40wascvnqd&dl=0",
    miljoPictures:
      "https://www.dropbox.com/scl/fo/epyau6y9h2ha3tw3s3g37/h?rlkey=zjz7zlpo7p5tpw7691k0cbewu&dl=0",
    productText: `
    Multifunksjonelle produkter, 100% veganske, hudvennlige og bevisste. 
    Inspirert av naturen og støttet av vitenskapen. 
    <br />
    <br />
    Opplev den nye æraen av koreansk kosmetikk med Meisani!
    <br />
    <br />
    Meisani formulerer med "less is more" -tilnærming med skånsomme ingredienser. 
    Ikke mer enn det huden din trenger. Alle formuleringene  gjennomgår en nøye utvelgelse av 
    ingredienser som er trygge for alle hudtyper, spesielt sensitiv hud, og tar hensyn til 
    delikate områder som øyeområdet.
    <br />
    <br />
    Meisani forplikter seg til å produsere formler som er trygge for huden og miljøet ved å 
    kun samarbeide med produsenter og leverandører som er i tråd med våre sikkerhets-, 
    etiske og miljømessige standarder. Alle Meisani produkter er 100% veganske.
    `,
  },
  {
    name: "Steambase",
    pictures: [steambaseOne, steambaseTwo, steambaseThree, steambaseFour],
    banner: steambaseBanner,
    instagram: "https://www.instagram.com/steambase_official/",
    facebook: "https://www.facebook.com/p/steambaseglobal-100063831159475/",
    productPictures:
      "https://www.dropbox.com/scl/fo/glwruxn34mjcd9xyu2kpe/h?rlkey=bgry60egqim390o6cbgi6aqde&dl=0",
    miljoPictures:
      "https://www.dropbox.com/scl/fo/temovjh38zqz2mrqhr3mq/h?rlkey=ctbefgzwuzapn10pf10lrad52&dl=0",
    productText: `
    Drøm deg bort til en verden av avslapning og velvære med Steambase revolusjonerende 
    øyemaskermed damp!
    <br />
    <br />
    Steambaseøyemasker tar selvpleie til nye høyder ved å kombinere 
    beroligende varmeogrevitaliserende damp, alt pakket inn i en liten, luksuriøs pakke.
     Når du åpner vår eksklusive øyemaske, utløses en varm damp som mykgjør huden rundt øynenedine 
     og frigjør spenninger etter en lang dag. Den beroligende dampen åpner porene og gir 
     enforyngende følelse, samtidig som den forbedrer sirkulasjonen og reduserer synligheten 
     avtrøtthetstegn.Maskene kommer i åtte unike varianter og er beriket med naturlige ekstrakter 
     og essensielle oljersom beroliger og nærer huden, og gir deg en følelse av total avslapning og velvære.
     <br />
     <br />
     Enten det eretter en travel dag på jobb, eller som en del av din egenpleierutine, 
     vil våre øyemasker fraSteambase være din ultimate følgesvenn for å lade opp og gi næring til både 
     kropp og sjel.Opplev den ultimate luksusen av en spa-behandling hjemme med Steambase øyemasker med damp.
     <br />
     <br />
     Gi deg selv den pausen du fortjener!
    `,
  },
  {
    name: "Suntique",
    pictures: [suntiqueOne, suntiqueTwo, suntiqueThree],
    banner: suntiqueBanner,
    instagram: "https://www.instagram.com/suntique_official/",
    facebook: "https://www.facebook.com/soel.no",
    productPictures:
      "https://www.dropbox.com/sh/vuianxr04sx34xt/AABT7qXzXqB-zIHrQFxq25MWa?dl=0",
    miljoPictures:
      "https://www.dropbox.com/sh/g3ipx1p1qtok3tb/AAAP9EkGtKhNn1Pn-3TIqQVqa?dl=0",
    productText: `
        Suntique er et koreansk hud- og hårpleiemerke som har spesialisert seg
        på individuelt tilpasset solbeskyttelse og trygge ingredienser. Suntique
        vet at ikke all hud er lik og har derfor laget solkremer og aftersun med
        dette i tankene!
        <br />
        <br />
        Suntique har solbeskyttelse for alle aldre, alle hudtyper, inkludert de
        med svært sensitiv hud. Varemerket har alt fra solkrem, solsticks,
        BB-cushion, serum, hårspray og aftersun! Her får du solkrem og
        fuktighetskrem i ett.
        <br />
        <br />
        SPF og PA- beskyttelse er en av de viktigste forebyggende
        hudpleieproduktene som finnes og like viktig hele året. Likevel vet vi
        at det kan være vanskelig å finne en god, ikke-irriterende solkrem for
        sensitiv hud. Mange solkremer for tørr hud er ikke fuktighetsgivende nok
        og legger seg som et tykt lag på huden eller gir masse white-cast. I
        tillegg opplever mange at solkrem fører til utslett og akne og at det er
        vanskelig å legge makeup over solkremen med ett fint resultat.
        <br />
        <br />
        Etter flere år med leting og testing har vi med Suntique, endelig funnet
        et solpleiemerke som beskytter og forebygger og som oppfyller alle våre
        krav, uten at dette går på bekostning av huden din!
        <br />
        <br />
        «Sun Lifeguard» - «Suncare A to Z»
      `,
  },
  {
    name: "Haruharu Wonder",
    pictures: [
      haruharuwonderOne,
      haruharuwonderTwo,
      haruharuwonderThree,
      haruharuwonderFour,
    ],
    banner: haruBanner,
    instagram: "https://www.instagram.com/haruharuwonder_official/",
    facebook: "https://www.facebook.com/official.Haruharu",
    productPictures:
      "https://www.dropbox.com/sh/xydh85y6djncr36/AACvrNFH0HlF84AOwaODGTIha?dl=0",
    miljoPictures:
      "https://www.dropbox.com/sh/njfwiisvrj49zpg/AAD-ATjGH1Kp3U9eNueuhWraa?dl=0",
    productText: `
        Haruharu wonder er en livstilsren og vegansk hudpleieserie fra Sør-Korea
        med patentert fermentert svart ris og bark av bambusskudd. Med
        førsteklasses hudforsterkende ingredienser oppfyller Haruharu wonder
        dine daglig behov.
        <br />
        <br />
        Haruharu wonders visjon er å utvikle produkter som hjelper deg å gjøre
        et klokere og enklere valg. Haruharu wonders milde produkter inneholder
        naturlige, plantebaserte ingredienser med kliniske beviste fordeler. Med
        deres innovative bruk av fermenterte ingredienser, tar de til orde for
        at det finne bedre, bærekraftige løsninger, uten at dette går på
        bekostning av din hud.
        <br />
        <br />
        Haruharu wonders produkter er ett trygt valg for å oppnå en sunn og frisk
        hud, som gløder.
        <br />
        <br />
        Clean life, Clean beauty.
        <br />
        <br />
        Haruharu betyr hverdag på koreansk, og wonder er rett og slett
        forkortelsen av ordet wonderful. Med navnets nydelige betydning ønsker
        haruharu wonder å være en del av din hverdagsrutine.
      `,
  },
];

export const outletData = {
  brands: [
    {
      name: "Klairs",
      logo: klairsLogo,
      countries: {
        norway: {
          urls: [
            "http://www.vitusapotek.no",
            "http://www.blush.no",
            "http://www.eleven.no",
            "http://www.nordicfeel.no",
            "http://www.apotera.no",
            "http://www.farmasiet.no",
            "http://www.coverbrands.no",
            "http://www.vita.no",
            "http://www.blivakker.no",
          ],
        },
        sweden: {
          urls: [
            "http://www.apotea.se",
            "http://www.eleven.se",
            "http://www.nordicfeel.se",
            "http://www.cocopanda.se",
          ],
        },
        denmark: {
          urls: [
            "http://www.cocopanda.dk",
            "http://www.nordicfeel.dk",
            "http://www.eleven.dk",
          ],
        },
        finland: {
          urls: [
            "http://www.nordicfeel.fi",
            "http://www.eleven.fi",
            "http://www.cocopanda.fi",
          ],
        },
      },
    },
    {
      name: "By Wishtrend",
      logo: wishtrendLogo,
      countries: {
        norway: {
          urls: [
            "http://www.blush.no",
            "http://www.eleven.no",
            "http://www.nordicfeel.no",
            "http://www.apotera.no",
            "http://www.farmasiet.no",
            "http://www.coverbrands.no",
            "http://www.vita.no",
            "http://www.blivakker.no",
          ],
        },
        sweden: {
          urls: [
            ,
            "http://www.eleven.se",
            "http://www.nordicfeel.se",
            "http://www.cocopanda.se",
          ],
        },
        denmark: {
          urls: [
            "http://www.cocopanda.dk",
            "http://www.nordicfeel.dk",
            "http://www.eleven.dk",
          ],
        },
        finland: {
          urls: [
            "http://www.nordicfeel.fi",
            "http://www.eleven.fi",
            "http://www.cocopanda.fi",
          ],
        },
      },
    },
    {
      name: "Suntique",
      logo: suntiqueLogo,
      countries: {
        norway: {
          urls: [
            "http://www.blush.no",
            "http://www.eleven.no",
            "http://www.nordicfeel.no",
            "http://www.apotera.no",
            "http://www.farmasiet.no",
            "http://www.coverbrands.no",
            "http://www.vita.no",
            "http://www.blivakker.no",
            "http://www.thatglow.no",
            "http://www.lyko.com",
          ],
        },
        sweden: {
          urls: [
            "http://www.eleven.se",
            "http://www.nordicfeel.se",
            "http://www.cocopanda.se",
            "http://www.lyko.com",
            "https://www.apohem.se/",
            "https://www.meds.se/",
          ],
        },
        denmark: {
          urls: [
            "http://www.cocopanda.dk",
            "http://www.lyko.com",
            "http://www.nordicfeel.dk",
            "http://www.eleven.dk",
          ],
        },
        finland: {
          urls: [
            "http://www.nordicfeel.fi",
            "http://www.eleven.fi",
            "http://www.cocopanda.fi",
            "http://www.lyko.com",
          ],
        },
        austria: {
          urls: ["http://www.lyko.com"],
        },
        poland: {
          urls: ["http://www.lyko.com"],
        },
        germany: {
          urls: ["http://www.lyko.com"],
        },
      },
    },
    {
      name: "Rated Green",
      logo: ratedGreenLogo,
      countries: {
        norway: {
          urls: [
            "http://www.blush.no",
            "http://www.eleven.no",
            "http://www.nordicfeel.no",
            "http://www.apotera.no",
            "http://www.farmasiet.no",
            "http://www.vita.no",
            "http://www.lyko.com",
          ],
        },
        sweden: {
          urls: [
            "http://www.eleven.se",
            "http://www.nordicfeel.se",
            "http://www.lyko.com",
          ],
        },
        denmark: {
          urls: [
            "http://www.lyko.com",
            "http://www.nordicfeel.dk",
            "http://www.eleven.dk",
          ],
        },
        finland: {
          urls: [
            "http://www.nordicfeel.fi",
            "http://www.eleven.fi",
            "http://www.lyko.com",
          ],
        },
        austria: {
          urls: ["http://www.lyko.com"],
        },
        poland: {
          urls: ["http://www.lyko.com"],
        },
        germany: {
          urls: ["http://www.lyko.com"],
        },
      },
    },
    {
      name: "Haruharu Wonder",
      logo: haruharuLogo,
      countries: {
        norway: {
          urls: [
            "http://www.coverbrands.no",
            "http://www.blivakker.no",
            "http://www.apotek1.no",
            "http://www.lyko.com",
            "http://www.farmasiet.no",
            "http://www.apotera.no",
          ],
        },
        sweden: {
          urls: [
            "http://www.lyko.com",
            "https://www.apohem.se/",
            "https://www.kronansapotek.se/",
            "https://www.meds.se/",
          ],
        },
        denmark: {
          urls: ["http://www.lyko.com"],
        },
        finland: {
          urls: ["http://www.lyko.com"],
        },
        austria: {
          urls: ["http://www.lyko.com"],
        },
        poland: {
          urls: ["http://www.lyko.com"],
        },
        germany: {
          urls: ["http://www.lyko.com"],
        },
      },
    },
    {
      name: "I'm From",
      logo: imFromLogo,
      countries: {
        norway: {
          urls: [
            "http://www.coverbrands.no",
            "http://www.apotera.no",
            "http://www.youty.no",
            "http://www.elven.no",
            "http://www.nordicfeel.no",
            "http://www.lyko.com",
          ],
        },
        sweden: {
          urls: ["http://www.lyko.com"],
        },
        denmark: {
          urls: ["http://www.youty.dk", "http://www.lyko.com"],
        },
        finland: {
          urls: [
            "http://www.youty.fi",
            "http://www.nordicfeel.fi",
            "http://www.eleven.fi",
            "http://www.lyko.com",
          ],
        },
        austria: {
          urls: ["http://www.lyko.com"],
        },
        poland: {
          urls: ["http://www.lyko.com"],
        },
        germany: {
          urls: ["http://www.lyko.com"],
        },
      },
    },
    {
      name: "House Of Dowha",
      logo: houseOfDowhaLogo,
      countries: {
        norway: {
          urls: [
            "http://www.youty.no",
            "http://www.elven.no",
            "http://www.nordicfeel.no",
            "http://www.lyko.com",
          ],
        },
        sweden: {
          urls: ["http://www.lyko.com"],
        },
        denmark: {
          urls: ["http://www.youty.dk", "http://www.lyko.com"],
        },
        finland: {
          urls: [
            "http://www.youty.fi",
            "http://www.nordicfeel.fi",
            "http://www.eleven.fi",
            "http://www.lyko.com",
          ],
        },
        austria: {
          urls: ["http://www.lyko.com"],
        },
        poland: {
          urls: ["http://www.lyko.com"],
        },
        germany: {
          urls: ["http://www.lyko.com"],
        },
      },
    },
    {
      name: "Meisani",
      logo: meisaniLogo,
      countries: {
        norway: {
          urls: [
            "http://www.apotera.no",
            "http://www.youty.no",
            "http://www.elven.no",
            "http://www.nordicfeel.no",
            "http://www.lyko.com",
          ],
        },
        sweden: {
          urls: [
            "http://www.lyko.com",
            "https://www.apohem.se/",
            "http://www.apotea.se",
            "https://www.meds.se/",
          ],
        },
        denmark: {
          urls: ["http://www.youty.dk", "http://www.lyko.com"],
        },
        finland: {
          urls: [
            "http://www.youty.fi",
            "http://www.nordicfeel.fi",
            "http://www.eleven.fi",
            "http://www.lyko.com",
          ],
        },
        austria: {
          urls: ["http://www.lyko.com"],
        },
        poland: {
          urls: ["http://www.lyko.com"],
        },
        germany: {
          urls: ["http://www.lyko.com"],
        },
      },
    },
    {
      name: "Steambase",
      logo: steamBaseLogo,
      countries: {
        norway: {
          urls: [
            "http://www.apotera.no",
            "http://www.youty.no",
            "http://www.elven.no",
            "http://www.nordicfeel.no",
            "http://www.lyko.com",
          ],
        },
        sweden: {
          urls: [
            "http://www.lyko.com",
            "https://www.apohem.se/",
            "http://www.apotea.se",
            "https://www.meds.se/",
          ],
        },
        denmark: {
          urls: ["http://www.youty.dk", "http://www.lyko.com"],
        },
        finland: {
          urls: [
            "http://www.youty.fi",
            "http://www.nordicfeel.fi",
            "http://www.eleven.fi",
            "http://www.lyko.com",
          ],
        },
        austria: {
          urls: ["http://www.lyko.com"],
        },
        poland: {
          urls: ["http://www.lyko.com"],
        },
        germany: {
          urls: ["http://www.lyko.com"],
        },
      },
    },
  ],
};
