import star from "./icons/star.svg";
import truck from "./icons/truck.svg";
import thumbsUp from "./icons/thumbs-up.svg";
import instagram from "./icons/instagram.svg";
import facebook from "./icons/facebook.svg";
import linkedin from "./icons/linkedin.svg";
import youtube from "./icons/youtube.svg";
import tiktok from "./icons/tiktok.svg";
import instagramWhite from "./icons/instagramWhite.svg";
import facebookWhite from "./icons/facebookWhite.svg";
import linkedinWhite from "./icons/linkedinWhite.svg";
import tiktokWhite from "./icons/tiktokWhite.svg";
import youtubeWhite from "./icons/youtubeWhite.svg";

import logo from "./images/soel-logo.png";

const Assets = {
  icons: {
    star,
    truck,
    thumbsUp,
    instagram,
    facebook,
    linkedin,
    youtube,
    tiktok,
    instagramWhite,
    facebookWhite,
    linkedinWhite,
    tiktokWhite,
    youtubeWhite,
  },
  images: {
    logo,
  },
};

export default Assets;
