import React, { useState, useEffect } from "react";
import "../styles/Home.css";
import FrontPageImages from "../components/FrontPageImages";

import LogoCarousel from "../components/LogoCarousel";

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 870) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="main">
      <FrontPageImages />
      {!isMobile && <LogoCarousel />}
      <div className="infoboxes">
        <div className="infobox">
          <div className="infobox-title">FØRSTEKLASSES PRODUKTER</div>
          <div>
            Hvert produkt vi selger er nøye testet ut før salg. Vi selger kun
            produkter som er registrert i CPNP registeret og godkjent for salg i
            Europa, slik at kunden alltid skal få trygge og gode produkter
          </div>
        </div>
        <div className="infobox">
          <div className="infobox-title">ERFAREN DISTRIBUTØR</div>
          <div>
            Soél startet å distribuere hudpleieserien Klairs i Norge i 2016 og
            var en av de første til å introdusere koreansk hudpleie på det
            norske markedet.
          </div>
        </div>
        <div className="infobox">
          <div className="infobox-title">KVALITETS SAMARBEID</div>
          <div>
            Vi er opptatt av høy kvalitet i alle ledd og at Soéls
            samarbeidspartnere skal føle seg ivaretatt til enhver tid.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
